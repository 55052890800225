import React from 'react'
import loadable from "@loadable/component"
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { GatsbySeo, BlogPostJsonLd, BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import * as styles from '../styles/blogs.blogs.module.css';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import Link from '../utils/Link/Link';
import '@deckdeckgo/highlight-code';
import { defineCustomElements } from "@deckdeckgo/highlight-code/dist/loader";
const FooterCard = loadable(()=> import('../components/cards/FooterCard/FooterCard'));
const CommentTile =  loadable(()=> import('../components/tile/CommentTile/CommentTile'));
const Layout = loadable(()=> import('../hoc/Layout/Layout'));
const Card = loadable(()=> import('../components/cards/Card/Card'));



export default function BlogsDetails({data}) {
    const blog = data.mdx;
    const image = getImage(blog.frontmatter.image.childImageSharp)
    const disqusConfig = {
        config: { identifier: blog.slug, title: blog.frontmatter.title},
    }
    defineCustomElements();

    return (
        <Layout>
            <GatsbySeo
                language='en'
                title={`${blog.frontmatter.title} - Raysummee`}
                canonical={`https://www.raysummee.com/blogs/${blog.slug}`}
                description={`Read ${blog.frontmatter.title} on my website Raysummee.`}
                openGraph={{
                    url: `https://www.raysummee.com/blogs/${blog.slug}`,
                    title: `${blog.frontmatter.title} - Raysummee`,
                    type: 'article',
                    article: {
                    publishedTime: blog.frontmatter.date,
                    authors: [
                        'Angshuman Barpujari',
                    ],
                    // tags: ['Tag A', 'Tag B', 'Tag C'],
                    },
                    description:`Read ${blog.frontmatter.title} on my website Raysummee.`,
                    images: [
                        {
                            url: `https://www.raysummee.com${blog.frontmatter.image.publicURL}`,
                            width: 800,
                            height: 600,
                            alt: `image of ${blog.frontmatter.title}`,
                        }
                    ],
                    site_name: 'Raysummee',
                }}
            />
            <BreadcrumbJsonLd
                itemListElements={[
                    {
                        position: 1,
                        name: 'Blogs',
                        item: 'https://www.raysummee.com/blogs',
                    },
                    {
                        position: 2,
                        name: `${blog.frontmatter.title}`,
                        item: `https://www.raysummee.com/blogs/${blog.slug}`,
                    },
                ]}
            />
            <BlogPostJsonLd
                url={`https://www.raysummee.com/blogs/${blog.slug}`}
                headline='Blog headline'
                images={[
                    `https://www.raysummee.com${blog.frontmatter.image.publicURL}`
                ]}
                datePublished={blog.frontmatter.date}
                authorName='Angshuman Barpujari'
                description={`Read ${blog.frontmatter.title} on my website Raysummee.`}
                publisherLogo='https://www.raysummee.com/images/logo_wb.jpg'
                publisherName='Raysummee'
                overrides={{
                    '@type': 'BlogPosting',
                    author: {
                        "@type": "Person",
                        name: "Angshuman Barpujari",
                        url: "https://www.raysummee.com/",
                        alumniOf: {
                            "@type": "CollegeOrUniversity",
                            name: ["The Assam Kaziranga University", "Assam Jatiya Bidyalaya"]
                        },
                        knowsAbout: ["App Development", "Machine Learning", "Web Development", "Computer Science"],
                        email: "raysummee@gmail.com",
                        gender: "male",
                        nationality: "Indian",
                        jobTitle: "SDE-I",
                    }
                }}            
            />
            <div className={styles.scrollable}>
                <Card className={styles.card}>
                    <GatsbyImage image={image} alt="" className={styles.img_container} imgClassName={styles.img}/>
                    <h1 className={styles.title}>{blog.frontmatter.title}</h1>
                    <span className={styles.date}>{blog.frontmatter.isoDate}</span>
                    <MDXProvider components={{
                        a: Link,
                    }}>
                        <MDXRenderer >{blog.body}</MDXRenderer>
                    </MDXProvider>
                    <CommentTile config={disqusConfig}/>
                </Card>
                <FooterCard className={styles.footer_card}/>
            </div>
        </Layout>
    )
}
export const query = graphql`
    query blog($slug: String) {
        mdx(slug: {eq: $slug},) {
            frontmatter {
                date
                isoDate: date(formatString: "DD MMM YYYY")
                title
                published
                image {
                    publicURL
                    childImageSharp {
                        gatsbyImageData(
                            height: 170
                            width: 200
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
            body,
            slug
        }
    }
`